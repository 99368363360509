const colors = {
  // Created with https://smart-swatch.netlify.app/#1A4EB0
  blue: {
    50: '#e4efff',
    100: '#bad0f9',
    200: '#8fb1f0',
    300: '#6391e8',
    400: '#3972e0',
    500: '#2159c7',
    600: '#17459c',
    700: '#0e3170',
    800: '#051e46',
    900: '#000a1c',
  },
}
export { colors }
