import React from 'react'
import {
  ChakraProvider,
  CSSReset,
  GlobalStyle,
  PortalManager,
} from '@chakra-ui/react'
import { theme } from './src/theme'

// https://chakra-ui.com/guides/integrations/with-gatsby#chakra-providers

export const wrapRootElement = ({ element }, {}) => {
  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      <GlobalStyle />
      <PortalManager>{element}</PortalManager>
    </ChakraProvider>
  )
}
