import { extendTheme } from '@chakra-ui/react'
import { styles } from './styles'
import { fonts } from './fonts'
import { components } from './components'
import { colors } from './colors'

// Theming best practices
// https://chakra-ui.com/docs/theming/customize-theme#scaling-out-your-project

const theme = extendTheme({
  styles,
  fonts,
  components,
  colors,
})

type Theme = typeof theme

/**
 * Components names available for theming. It can be used like an `enum`.
 */
// const componentsThemable = stringArrToObj(keys(theme.components))

// type ComponentsThemable = typeof componentsThemable

export { theme, Theme /* componentsThemable, ComponentsThemable */ }
