// Chakra Theme overrides
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/button.ts

const buttonTheme = {
  baseStyle: {
    borderRadius: 'base',
    fontWeight: 'medium',
  },
  defaultProps: {
    size: 'sm',
    colorScheme: 'blue',
  },
}

export { buttonTheme }
