// Chakra Theme overrides
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/menu.ts

const menuTheme = {
  baseStyle: {
    item: {
      py: '0.2rem',
    },
    groupTitle: {
      mx: 3,
    },
  },
}

export { menuTheme }
