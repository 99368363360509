// Chakra Theme overrides
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/input.ts

const inputTheme = {
  baseStyle: {},
  defaultProps: {
    size: 'sm',
  },
}

export { inputTheme }
