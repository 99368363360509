import { Styles } from '@chakra-ui/theme-tools'

// Global styles override

const styles: Styles = {
  global: {
    // Global styles
    // https://chakra-ui.com/docs/features/global-styles
  },
}

export { styles }
